<template>
    <div class="hover-card">
        <figure>
            <div style="height: 12rem; margin-top: 1rem;"  class="cursor_pointer"   @click="actDetailsPage">
              <div v-if="onHoverSelectedItem.isActiveVideo" style="position:relative;">
                <video :src="landscapeVideo" :id="`sliderVideoTrack-${id}`" loading="lazy" alt="" :poster="landscapseImage" class="image__item_video" muted autoplay loop />
                <div class="mute-unmute-btn-slider" @click="ChangeMute" v-if="landscapeVideo">
                  <img :src="ismuted ? Mute : Unmute" alt="volumn mute" />
                </div>
              </div>
                <!-- <img src="@/assets/placeholder-lands.svg" loading="lazy" class="image__item" v-else/> -->
                <img :src="landscapseImage" loading="lazy" class="image__item" :onerror="`this.onerror=null; if(!this.src.includes('${placeholder_lands}')); this.src='${placeholder_lands}';`" v-else/>
            </div>
          <figcaption class="related-meta-data">
           
            <div class="related-action-btns">
                <div class="watch-now-btn">
                <PlayButton :whereIamFrom="'hoverCard'" :content="content || null" :playerInstance="playerInstance" v-if="selected"></PlayButton>
              </div>
              <div class="add-to-watch-list">
                <WatchlistBtn :content="content || null" v-if="selected"/>
              </div>
              <div class="add-to-watch-list">
                <LikeBtn :content="content || null" v-if="selected"/>
              </div>
              
            </div>
            <div class="related-meta-data-details" v-if="onHoverSelectedItem">
              <h1 class="fig-title">{{ onHoverSelectedItem.title }}</h1>
              <p class="fig-details">
                <span class="pg-rating" v-if="onHoverSelectedItem.advisory">{{ onHoverSelectedItem.advisory }}</span>    
                <span class="year" v-if="onHoverSelectedItem.productionyear">{{ onHoverSelectedItem.productionyear }}</span>
                <span class="pg-rating" v-if="onHoverSelectedItem.pgrating">{{onHoverSelectedItem.pgrating}}</span>
                <span class="duration" v-if="onHoverSelectedItem.duration">{{ this.calcTimeFormat(onHoverSelectedItem.duration) }}</span>
              </p>
              <div class="fig-description" v-if="onHoverSelectedItem && onHoverSelectedItem.shortdescription">
                <!-- <span>{{onHoverSelectedItem.shortdescription}}</span> -->
                <span>{{ spliceText(onHoverSelectedItem.shortdescription,90) }}</span>
              </div>
            </div>
          </figcaption>
        </figure>
    </div> 
</template>

<script>
import Utility from "@/mixins/Utility.js";

export default {

    name: "GridHoverCard",
    props: ['content'],
    data() {
        return {
            selected: false, 
            ismuted: true,
            Mute: require('@/assets/icons/mute.png'),
            Unmute: require('@/assets/icons/unmute.png'),
            placeholder_port: require('@/assets/placeholder-port.svg'),
            placeholder_lands: require('@/assets/placeholder-lands.svg'),
            cronjob: "",
            videoInstance: "",
            landscapseImage: "",
            landscapeVideo: "",
            onHoverSelectedItem:null,
            selected: true
        }
    },
    methods: {
        actDetailsPage () {
            this.$router.push({name: 'detailPageEarth', params:{
                title: this.onHoverSelectedItem.defaulttitle,
                contentId: this.onHoverSelectedItem.objectid
            }})
        },
        calcTimeFormat(val) {
            if (!val) return;
            let totalTime = val;
            let hours = Math.floor(totalTime / 3600);
            let minutes = Math.floor((totalTime - hours * 3600) / 60);

            let showTime =
                (hours ? hours.toString().padStart(2, "0") +  this.$t("hr") : "") +
                " " +
                (minutes ? minutes.toString().padStart(2, "0") + (minutes === 1 ? this.$t("min") : this.$t("mins")) : "");

            if (showTime == " ") {
                return val + this.$t("secs");
            } else {
                return showTime;
            }
        },    
        ChangeMute() {
            this.ismuted = !this.ismuted;
            let video = document.getElementById(`sliderVideoTrack-${this.id}`);
            video.muted = !video.muted;
        },
        getVideo (contentDetails) {
            return contentDetails.trailer && contentDetails.trailer[0] && contentDetails.trailer[0].filelist[0].filename ?  contentDetails.trailer[0].filelist[0].filename : null;
        },
        getPoster(content, type) {
            if (Array.isArray(content.poster)) {
                if (content.poster && content.poster.length) {
                let index = content.poster.findIndex((element) => {
                    return (element.quality === "SD" || element.quality === "HD") && element.postertype === type;
                });
                let img = 0;
                if (index > -1) {
                    content.poster[index]?.filelist?.forEach(element => {
                    if (element.quality == 'SD' || element.quality == 'HD') {
                        // return element.filename
                        img = element.filename;
                    }
                    });
                    if (img) {
                    return img
                    } else {
                    return this.placeholder_lands;
                    }
                } else {
                    return this.placeholder_lands;
                }
                }
            } else if (content.inwatchlist) {
                return content.poster.landscape;
            } else if  (content.thumbnail ) {
                return  this.placeholder_lands;
                } else {
                return  this.placeholder_lands;
                }
        },
        spliceText(text, maxLength) {
            if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...'; // Splice text and add ellipsis
            } else {
            return text; // If text is within the limit, return it as is
            }
        },
        // handingTiming (status, item, e) {
        //     clearTimeout(this.timerOn);
        //     this.timerOn = setTimeout(() => {
        //         this.actOnHoverPlayerActive(status, item, e)
        
        //     }, 1200);

        // },
        // actOnHoverPlayerActive (type, item, event) {
        //     if (window.innerWidth < 600) {
        //     this.actDetailsPage();
        //     return;
        //     }
        //     this.selected = type;
        //     this.onHoverSelectedItem = item;
        //     this.landscapseImage = this.getPoster(item,'LANDSCAPE')
        //     this.landscapeVideo = "";
        //     this.landscapeVideo = this.getVideo(item)
        //     this.onHoverSelectedItem.isActiveVideo = false;
        //     this.autoPosterVideoSwitch();
        //     let rect = event.target.getBoundingClientRect();
        //     let fromLeft = rect.left;
        //     let mainElement = document.getElementsByClassName('main-'+this.id);  


        //     this.videoInstance =  document.getElementById(`sliderVideoTrack-${this.id}`);

        //     let resizing = 2.5;
        //     let isOnlySlider = (rect.height * resizing) 

        //     if (parseInt(window.innerWidth) > 1700) {
        //     isOnlySlider = (rect.height * 4 );
        //     }
        //     if (parseInt(window.innerWidth) > 1500 && parseInt(window.innerWidth) < 1680) {
        //     isOnlySlider = (rect.height * 2.5 );
        //     }
        //     if (this.$route.name != 'Home') {
        //     isOnlySlider = -10;
        //     }
        //     if (type) {
        //     this.ismuted = true;
        //     setTimeout(() => {
        //         let xValue = (fromLeft + window.scrollX );
        //         let yValue = (rect.top + window.scrollY  - ( isOnlySlider ))
        //         xValue = xValue == 0 ? 60 : xValue - 40;
        //         document.getElementsByClassName('onhover-'+this.id)[0].style.transform = "translate("+  xValue + "px," +  yValue  + "px)";
        //     }, 10);
        //     }
        //     document.getElementsByClassName('top-carousel-class').forEach((item) => {
        //     item.muted = true
        //     })
        //     eventBus.$emit('event-isMuted');
        // },
        // autoPosterVideoSwitch () {
        //     this.onHoverSelectedItem = JSON.parse(JSON.stringify(this.onHoverSelectedItem));

        //     setTimeout(() => {
        //     this.onHoverSelectedItem.isActiveVideo = true; 
        //     }, 1000)
        // },
    },
    created() {
    },
    mounted () {
        this.onHoverSelectedItem = this.content;
        // handingTiming (true, item, e);
        this.landscapseImage = this.getPoster(this.content, 'LANDSCAPE')
    },
    components: {
        WatchlistBtn: () => import( /* webpackChunkName: "watchlisbutton" */ "@/components/SvgImages/WatchList.vue"),
        LikeBtn: () => import( /* webpackChunkName: "likeBtn" */ "@/components/SvgImages/LikeBtn.vue"),
        PlayButton: () => import(/* webpackChunkName: "playButton" */ "@/components/buttons/play.vue")
    },
    beforeDestroy() {
        this.content = null;
    },
    mixins: [Utility],
}
</script>
<style scoped lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
    :root {
      --blue: #1e90ff;
      --white: $clr-saina-light-dark-txtf;
      --VERTICAL-CARD-HOVER-SCALE-X: 0.8;
      --VERTICAL-CARD-HOVER-SCALE-Y: 0.8;
    }
    .hover-card {
        width: 21rem;
        height: auto;
        padding-bottom: 1rem;
        background: #101113;
        transform-origin: center center;
        animation: kdUbkV2MkpUdd4wNQxnlN 0.6s ease-in;  
        position: relative;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 5px 3px #00000073;
        figure {
        width: 100%;
        height: 50%;
        .image__item_video {
            width: 100%;
            height: 100%;
            border-radius: 0.5rem 0.5rem 0 0;
            height: 13rem;

        }
        .image__item {
            width: 100%;
            height: 100%;
            border-radius: 0.5rem 0.5rem 0 0;
        }
        .related-meta-data {
            font-family: 'Roboto';
            padding: 0.75rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .related-action-btns{
            display: flex;
            gap: 1rem;
            margin-top: 0.5rem;
            .watch-now-btn {
                width: 3rem;
                height: 3rem;
                display: flex;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: rgba(0, 0, 0, 1);
                padding-left: 6px;
                padding-top: 1px;
                cursor: pointer;
                .btn-status {
                font-size: 1rem;
                font-family: 'Roboto';
                font-weight: 600;
                }
            }
            .add-to-watch-list {
                height: 2.5rem;
                width: 2.7rem !important;
                img {
                width: 100%;
                height: 100%;
                }
            }
            }
            .related-meta-data-details{
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .fig-title {
                font-weight: 500;
                font-size: 1.25rem;
            }
            .fig-details {
                display: flex;
                gap: 1rem;
                .pg-rating {
                background: #282828;
                border-radius: 0.25rem;
                padding: 0.1rem 0.24rem;
                font-weight: 600;
                font-size: 0.8rem;
                }
                .year, .duration{
                color: rgba(170, 170, 170, 1);
                font-weight: 400;
                font-size: 1rem;
                }
            }
            .fig-description {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre-wrap;
                color: rgba(170, 170, 170, 1);
                font-weight: 400;
                font-size: 0.8rem;
                height: 2rem;
            }
            }
        }
        }
    }
</style>